$(document).ready(function() {
    $("html").removeClass("no-js");
    
    var pageTop = $(document).scrollTop()
    var pageBottom = pageTop + $(window).height()
    var tags = $(".fade_in")
    
    setTimeout(function(){
        for (var i = 0; i < tags.length; i++) {
            var tag = tags[i]

            if ($(tag).position().top < pageBottom) { 
                $(tag).addClass("visible")
            }
        }
    
    }, 50);
        
	$(".nav_trigger").click(function(e) {
		e.preventDefault();
		$("html").toggleClass("nav--on");
	});
    
    $(".card_slider").slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    
    $(".image_slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendArrows: ".image_slider_nav"
    });
    
    $(".testimonial_slider").slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    jQuery.validator.setDefaults({
      success: "valid"
    });
    $('#contactForm').validate({
        errorElement: "span",
        errorClass: "error",
        rules: {
            "from-name": "required",
            "description": "required",
            "zip": "required",
            "phone": {
                required: true,
                phoneUS: true
            },
            "email": {
                required: true,
                email: true
            }
        },
        messages: {
            "from-name": "Please enter your full name",
            "email": "Please enter a valid email address",
            "phone": "Please enter your phone number",
            "zip": "Please enter a valid US zip code",
            "description": "Please enter a message"
        },
        errorPlacement: function(error, element) {
            $(element).parent('div').append('<span class="error_message"></span>');
            error.appendTo( $(element).parent('div').find('.error_message') );
         },
        highlight: function(element) {
            $(element).parent('div').addClass('error');
        },
        unhighlight: function(element) {
            $(element).parent().removeClass("error");
        }
    });
});

$(document).on("scroll", function () {
    var pageTop = $(document).scrollTop()
    var pageBottom = pageTop + ($(window).height() - 200)
    var tags = $(".fade_in")

    for (var i = 0; i < tags.length; i++) {
        var tag = tags[i]

        if ($(tag).position().top < pageBottom) { 
            $(tag).addClass("visible")
        }
    }
});

$(".contact_form btn").on('click', function(){
    $('.valid').parent('div').removeClass('error');
    $('#contactForm').submit();
    return false;
    e.preventDefault;
});